<script lang="ts" setup>
import { useDebouncedRef } from '~/utils/debounce.util';

const props = defineProps({
  value: { required: false, type: String, default: '' },
  placeholder: { required: false, type: String, default: '' },
  icon: { required: false, type: String, default: '' },
});
const emit = defineEmits(['onChange']);
const query = useDebouncedRef('', 400);

watch(query, (value: any) => {
  emit('onChange', value);
});
</script>

<template>
  <div class="custom-text-input">
    <v-text-field
      v-model="query"
      :prepend-inner-icon="icon"
      variant="outlined"
      hide-details
      :placeholder="placeholder"
      clearable
      single-line
      density="compact"
      clear-icon="mdi-close"
      :value="props.value === '*' ? '' : props.value"
    />
  </div>
</template>

<style lang="scss">
.custom-text-input{
  .v-input {
    &__control {
      min-height: 44px !important;

      .v-field {
        min-height: 44px !important;
        border-radius: 4px;
        margin: 0;
        box-shadow: none !important;
        padding: 0 12px !important;
        @extend .border-gray-5;
        @extend .bg-gray-5;
        @extend .text-black-1;

        &__input{
          padding: 0;
          height: 100%;

          &::placeholder {
            @extend .heading-desktop-17;
            @extend .text-black-1;
          }

          &:focus {
            @extend .border-gray-2;
            @extend .bg-gray-5;
          }
        }

        .v-input__prepend-inner,
        .v-input__append-inner {
          margin-top: 8px !important;
        }

        fieldset {
          @extend .border-gray-2;
        }

      }

      .v-text-field__details {
        display: none;
      }
    }
  }
}
</style>
