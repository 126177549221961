import _ from 'lodash';
import moment from 'moment';
import { parseContainerBookmark, prepareQueryParamsForContainerRequest } from '~/utils/container.util';
import { useUserStore } from '~/store/user';

export const useContainersStore = defineStore('containersStore', {
  state: (): any => ({
    showFiltersSideModal: false,
    showSaveFiltersView: false,
    containers: [],
    total: 0,
    page: 1,
    per_page: 20,
    isNewFilter: false,
    editFilters: {},
    showSortModal: false,
    showGroupByModal: false,
    showContainerViewModal: false,
    selectedContainers: [],
    containerView: 'table',
    calendarView: 'month',
    isFetching: false,
    reloadPage: '',
  }),
  actions: {
    async fetchContainers(routeQuery: any) {
      this.isFetching = true;
      try {
        const userStore = useUserStore();
        if (!userStore.activeOrgId) {
          return { containers: [], total: 0, page: 1, per_page: 20 };
        }
        const { $ofetch }: any = useNuxtApp();
        const oregStore = useOrgStore();
        const params: string = prepareQueryParamsForContainerRequest(routeQuery, oregStore.activeCustomFieldsFlatten);
        const runtimeConfig = useRuntimeConfig();

        const url = `${runtimeConfig.public.BUI_URL}bill-of-lading-tracking/?org_id=${userStore.activeOrgId}&${params}`;
        const { hits, found }: any = await $ofetch(url, { method: 'GET' });
        if (!routeQuery.sortBy) {
          const sortedHits = hits.sort((a: any, b: any) => b.created - a.created);
          this.containers = sortedHits.map((container: any) => parseContainerBookmark(container));
        }
        else {
          this.containers = hits.map((container: any) => parseContainerBookmark(container));
        }
        this.total = found;
        this.page = routeQuery.page;
      }
      catch (e) {
        console.error(e);
      }
      finally {
        this.isFetching = false;
      }
    },
    async fetchGroupByRelatedContainers(routeQuery: any) {
      try {
        const userStore = useUserStore();
        if (!userStore.activeOrgId) {
          return { containers: [], total: 0, page: 1, per_page: 20 };
        }
        const oregStore = useOrgStore();
        const params: string = prepareQueryParamsForContainerRequest(routeQuery, oregStore.activeCustomFieldsFlatten);
        const runtimeConfig = useRuntimeConfig();
        const { $ofetch }: any = useNuxtApp();
        const url = `${runtimeConfig.public.BUI_URL}bill-of-lading-tracking/?org_id=${userStore.activeOrgId}&${params}`;
        return await $ofetch(url, { method: 'GET' }).then(({ hits, found }: any) => {
          const containers = hits.map((container: any) => parseContainerBookmark(container));
          return { containers: containers, total: found };
        }).catch((error: any) => {
          console.error(error);
        });
      }
      catch (e) {
        console.error(e);
      }
    },
    deleteContainer(payload: any) {
      const { $ofetch }: any = useNuxtApp();
      const runtimeConfig = useRuntimeConfig();
      this.isFetching = true;
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        return await $ofetch(`${runtimeConfig.public.BUI_URL}bill-of-lading-tracking/actions/system-delete`, {
          method: 'post', body: payload,
        }).then(() => {
          this.updateDeletedContainer(payload);
          this.isFetching = false;
          return resolve(true);
        }).catch((err: any) => {
          this.isFetching = false;
          return reject(err);
        });
      });
    },
    async downloadContainers(payload: any, allSelected: boolean) {
      const oregStore = useOrgStore();
      const userStore = useUserStore();

      const idFilter = allSelected ? '' : `&&id:[${this.selectedContainers.join(',')}]`;
      const customFields = oregStore.activeCustomFieldsFlatten;
      const params: string = prepareQueryParamsForContainerRequest(payload, customFields, idFilter, true);
      const runtimeConfig = useRuntimeConfig();
      const apiEndPoint = (payload.mode === 'email') ? `download_containers?email=${userStore.email}&org_id=${userStore.activeOrgId}` : `download_containers?org_id=${userStore.activeOrgId}`;
      const host: any = `${runtimeConfig.public.BUI_URL}bill-of-lading-tracking`;
      const searchLength = payload?.q?.length ? payload?.q?.length : 0;
      const num_typos = searchLength < 8 ? 2 : 0;
      const url = `${host}/${apiEndPoint}&${params} &num_typos=${num_typos}&`;

      const { $ofetch }: any = useNuxtApp();
      return await $ofetch(url).then((res: any) => {
        if (payload.mode === 'download') {
          const url = window.URL.createObjectURL(res);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `container_report${new Date().toISOString()}.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
      }).catch((err: any) => console.log(err));
    },
    archiveContainer(payload: any) {
      const { $ofetch }: any = useNuxtApp();
      const runtimeConfig = useRuntimeConfig();
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        return await $ofetch(`${runtimeConfig.public.BUI_URL}bill-of-lading-tracking/actions/delete`, {
          method: 'post', body: payload,
        }).then(() => {
          this.updateDeletedContainer(payload);
          return resolve(true);
        }).catch((err: any) => reject(err));
      });
    },
    reIndexData() {
      const userStore = useUserStore();
      const orgId: any = userStore.activeOrgId;
      const runtimeConfig = useRuntimeConfig();
      const oneYearAgo = moment().subtract(12, 'months').format('YYYY-MM-DD');
      const { $ofetch }: any = useNuxtApp();
      const host: string = `${runtimeConfig.public.BUI_URL}bill-of-lading-tracking`;
      return $ofetch(`${host}/reindex/${orgId}?from_date=${oneYearAgo}`, { method: 'POST' }).then((response: any) => {
        alert(response);
      }).catch((err: any) => console.log(err));
    },
    setFiltersSideModalStatus(status: boolean) {
      this.showFiltersSideModal = status;
    },
    setSaveFiltersStatus(status: boolean) {
      this.showSaveFiltersView = status;
    },
    setIsNewFilter(value: boolean) {
      this.isNewFilter = value;
    },
    setEditFilters(value: any) {
      this.editFilters = _.cloneDeep(value);
    },
    setShowSortModal(value: any) {
      this.showSortModal = value;
    },
    setShowGroupByModal(value: any) {
      this.showGroupByModal = value;
    },
    setShowContainerViewModal(value: any) {
      this.showContainerViewModal = value;
    },
    setContainerView(value: string) {
      this.containerView = value;
    },
    setSelectedContainers(list: Array<any>) {
      this.selectedContainers = list && list.length ? _.cloneDeep(list) : [];
    },
    updateDeletedContainer(ids: any) {
      this.total = this.total - ids.length;
      this.selectedContainers = [];
      this.containers = this.containers.filter(
        (container: any) => !ids.includes(container.bookmark_id),
      );
    },
    setReloadPage() {
      this.reloadPage = new Date().toISOString();
    },
  },
});
